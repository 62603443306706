const config = {
    region: "us-west-1",
    awsAccountId: "089600871681",
    clientId: "20afogkcjs5qjaepno7t5n34db",
    identityPoolId: "us-west-1:fb2f33a9-7a5a-4dae-a281-bdfd6432c4c1",
    userPoolId: "us-west-1:a32a5ad6-c653-4f37-9b78-bb3a30e84d74",
    login: "cognito-idp.us-west-1.amazonaws.com/us-west-1_IMZg99zvk",
    data_bucket: "j2clark.info.data"
}

export { config }