import {
    CognitoIdentityProviderClient,
    SignUpCommand,
    ConfirmSignUpCommand,
    InitiateAuthCommand,
    UserNotConfirmedException,
    ResendConfirmationCodeCommand,
    RespondToAuthChallengeCommand,
    GlobalSignOutCommand
} from "@aws-sdk/client-cognito-identity-provider";
import {
    CognitoIdentityClient, GetCredentialsForIdentityCommand,
    GetIdCommand
} from "@aws-sdk/client-cognito-identity";

import { config } from '@/config';
import {SignatureV4} from "@aws-sdk/signature-v4";
import {Sha256} from "@aws-crypto/sha256-browser";
import {HttpRequest} from "@aws-sdk/protocol-http";

const cognitoUserPoolClient = new CognitoIdentityProviderClient({region: config.region});
const cognitoIdentityPoolClient = new CognitoIdentityClient({region: config.region});

export const auth = {
    namespaced: true,
    state: {
        // user: null,
        // identityId: null,
        authorizationTokens: null,
    },
    getters: {
        getAuthorizationTokens(state) {
            return state.authorizationTokens;
        },
        isAuthenticated(state) {
            const authenticated = state.authorizationTokens != null;
            // console.log('auth.isAuthenticated? ' + authenticated);
            // todo: verify not timed out

            // console.log('auth/isAuthenticated: is authenticated? ' + authenticated);
            return authenticated;
        },
    },
    mutations: {
        /*setUser(state, user) {
            state.user = user
        },*/
        setAuthorizationTokens(state, payload) {
            state.authorizationTokens = payload;
        },
    },
    actions: {
        async _signer(context) {
            const credentials = await context.dispatch('fetchAWSCredentials');
            return new SignatureV4({
                credentials: {
                    accessKeyId: credentials.AccessKeyId,
                    secretAccessKey: credentials.SecretKey,
                    sessionToken: credentials.SessionToken
                },
                region: 'us-west-1',
                service: 'execute-api',
                sha256: Sha256
            });
        },
        async _signHttpRequest(context, httpRequest) {
            const signer = await context.dispatch("_signer");
            try {
                const signedRequest = await signer.sign(httpRequest);
                delete signedRequest.headers.host
                return signedRequest.headers;
            } catch (error) {
                console.log('Signing Error: ' + JSON.stringify(error, null, 2));
                if (error.name === 'NotAuthorizedException') {
                    const authTokens = await context.getters.getAuthorizationTokens;
                    await context.dispatch('refreshAuth', authTokens);

                    return await context.dispatch('_signHttpRequest', httpRequest);
                } else {
                    await context.dispatch('_clearCredentials');
                    throw error;
                }
            }
        },
        async signGET(context, apiPath) {
            const apiHost = 'api.j2clark.info'
            const httpRequest = new HttpRequest({
                headers: {
                    // Why does this break things?
                    // 'Content-Type': 'application/json',
                    'host': apiHost
                },
                method: 'GET',
                path: apiPath
            });
            return await context.dispatch('_signHttpRequest', httpRequest);
        },
        async signPOST(context, payload) {
            const apiHost = 'api.j2clark.info'
            const apiPath = payload.apiPath;
            const body = payload.body;
            const httpRequest = new HttpRequest({
                body: JSON.stringify(body),
                headers: {
                    // Why does this break things?
                    // 'Content-Type': 'application/json',
                    'host': apiHost
                },
                method: 'POST',
                path: apiPath
            });
            return await context.dispatch('_signHttpRequest', httpRequest);
        },
        async fetchFederatedIdentityId(context) {
            try {
                const idToken = await context.getters.getAuthorizationTokens.IdToken;
                const userPoolUrl = config.login;
                const logins = {
                    // who logged us in? This is the app client url
                    [userPoolUrl]: idToken
                };

                const getIdCommand = new GetIdCommand({
                    IdentityPoolId: config.identityPoolId,
                    AccountId: config.awsAccountId,
                    Logins: logins
                })

                const getIdResponse = await cognitoIdentityPoolClient.send(getIdCommand);
                return getIdResponse.IdentityId;
            } catch (error) {
                console.log('fetchFederatedIdentityId error: ' + JSON.stringify(error, null, 2));
                // TODO: if auth issue, try to refresh
                /*if (error.name === 'NotAuthorizedException') {
                    const authTokens = await context.getters.getAuthorizationTokens;
                    await context.dispatch('refreshAuth', authTokens);

                    // recurse
                    return await context.dispatch('fetchFederatedIdentityId')
                }*/

                await context.dispatch('_clearCredentials');
                throw error;
            }
        },
        async fetchAWSCredentials(context) {
            const federatedIdentityId = await context.dispatch('fetchFederatedIdentityId');

            try {
                const idToken = await context.getters.getAuthorizationTokens.IdToken;
                const userPoolUrl = config.login;
                const logins = {
                    // who logged us in? This is the app client url
                    [userPoolUrl]: idToken
                };

                const getCredentialsForIdentityCommand = new GetCredentialsForIdentityCommand({
                    IdentityId: federatedIdentityId,
                    Logins: logins
                })
                const credentialsResponse = await cognitoIdentityPoolClient.send(getCredentialsForIdentityCommand);
                return credentialsResponse['Credentials'];
            } catch (error) {
                console.log('fetchAWSCredentials error: ' + JSON.stringify(error, null, 2));
                // TODO: if auth issue, try to refresh
                /*if (error.name === 'NotAuthorizedException') {
                    const authTokens = await context.getters.getAuthorizationTokens;
                    await context.dispatch('refreshAuth', authTokens);

                    // recurse
                    return await context.dispatch('fetchAWSCredentials')
                }*/

                await context.dispatch('_clearCredentials');
                throw error
            }
        },
        async refreshAuth(context, payload) {
            console.log('refreshAuth...');
            // https://docs.aws.amazon.com/cognito/latest/developerguide/amazon-cognito-user-pools-using-the-refresh-token.html
            // InitiateAuth with AuthFlow: REFRESH_TOKEN_AUTH
            try {
                const refreshToken = payload.RefreshToken;
                const idToken = payload.IdToken;

                const response = await cognitoUserPoolClient.send(new InitiateAuthCommand({
                    ClientId: config.clientId,
                    AuthFlow: 'REFRESH_TOKEN_AUTH',
                    AuthParameters: {
                        REFRESH_TOKEN: refreshToken
                    }
                }));

                const now = Date.now()
                const expiresIn = response.AuthenticationResult.ExpiresIn;
                const tokenExpiresTimestamp = (expiresIn * 1000) + now;
                const auth_tokens = {
                    TokenExpires: tokenExpiresTimestamp,
                    AccessToken: response.AuthenticationResult.AccessToken,
                    IdToken: idToken,
                    RefreshToken: refreshToken
                };

                // update storage
                localStorage.setItem('auth_tokens', JSON.stringify(auth_tokens));
                await context.commit("setAuthorizationTokens", auth_tokens);

                return auth_tokens;
            } catch (error) {
                console.log('refreshAuth error: ' + JSON.stringify(error, null, 2));
                await context.dispatch('_clearCredentials');
                throw error
            }
        },
        async requestCode(context, payload) {
            /*try {*/
            console.log('auth/requestCode payload: ' + JSON.stringify(payload));
            // API https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_ResendConfirmationCode.html
            // javascript https://docs.aws.amazon.com/AWSJavaScriptSDK/v3/latest/client/cognito-identity-provider/command/ResendConfirmationCodeCommand/
            const response = await cognitoUserPoolClient.send(new ResendConfirmationCodeCommand({
                ClientId: config.clientId,
                Username: payload.username
            }));
            console.log('requestCode response: ' + JSON.stringify(response))
            /*
            {
                "$metadata":{"httpStatusCode":200,"requestId":"6bbc8f52-00ae-4a08-8b40-89cae1e319ac","attempts":1,"totalRetryDelay":0},
                "CodeDeliveryDetails":{
                    "AttributeName":"email",
                    "DeliveryMedium":"EMAIL",
                    "Destination":"j***@j***"
                }
            }
             */
            return {
                destination: response.CodeDeliveryDetails.Destination,
                deliveryType: response.CodeDeliveryDetails.DeliveryMedium
            };
        },
        /*async _fetchCurrentUser(context, accessToken) {
            /!*
            No state update with this command - up to caller to decide what to do with data
             *!/
            try {
                // API https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_GetUser.html
                // javascript https://docs.aws.amazon.com/AWSJavaScriptSDK/v3/latest/client/cognito-identity-provider/command/GetUserCommand/
                const response = await cognitoUserPoolClient.send(new GetUserCommand({
                    AccessToken: accessToken
                }));

                const user = {
                    UserAttributes: response.UserAttributes,
                    Username: response.Username,
                };

                // set storage
                /!*localStorage.setItem('user', JSON.stringify(user));
                await context.commit("setUser", user);*!/

                return user;
            } catch (error) {
                console.log('fetchCurrentUser error: ' + JSON.stringify(error, null, 2));
                throw error;
            }
        },*/
        async tryLogin(context) {
            const auth_tokens_json = localStorage.getItem('auth_tokens')
            // const user_json = localStorage.getItem('user')

            const now = Date.now();
            if (auth_tokens_json/* && user_json*/) {
                const auth_tokens = JSON.parse(auth_tokens_json);
                // const user = JSON.parse(user_json);
                // await context.commit('setUser', user);

                if (auth_tokens.TokenExpires < now) {
                    // console.log('auth tokens expired - fetch auth_tokens...');
                    // console.log('refresh_token: ' + auth_tokens.RefreshToken);
                    try {
                        await context.dispatch("refreshAuth", auth_tokens);
                        console.log('auth/tryLogin authenticateTokens refreshed');
                    } catch (error) {
                        // console.error('auth/tryLogin authenticateTokens refresh failed');
                        console.error('auth/tryLogin authenticateTokens refresh failed: ' + JSON.stringify(error, null, 2));
                        // clear everything - force new login
                        await context.dispatch("_clearCredentials");
                    }
                } else {
                    await context.commit("setAuthorizationTokens", auth_tokens);
                }
            }
        },
        async initiateAuth(context, payload) {
            try {
                // API https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_InitiateAuth.html
                // javascript https://docs.aws.amazon.com/AWSJavaScriptSDK/v3/latest/client/cognito-identity-provider/command/InitiateAuthCommand/
                const response = await cognitoUserPoolClient.send(new InitiateAuthCommand({
                    ClientId: config.clientId,
                    AuthFlow: 'USER_PASSWORD_AUTH',
                    AuthParameters: {
                        USERNAME: payload.username,
                        PASSWORD: payload.password,
                    }
                }));

                const challenge = response.ChallengeName;
                if (challenge) {
                    //   ChallengeName: "SMS_MFA" || "SOFTWARE_TOKEN_MFA" || "SELECT_MFA_TYPE" || "MFA_SETUP" || "PASSWORD_VERIFIER" || "CUSTOM_CHALLENGE" || "DEVICE_SRP_AUTH" || "DEVICE_PASSWORD_VERIFIER" || "ADMIN_NO_SRP_AUTH" || "NEW_PASSWORD_REQUIRED",
                    if ('NEW_PASSWORD_REQUIRED' === challenge) {
                        // console.log('new_password_challenge: ' + JSON.stringify(response));
                        return  {
                            code: 'challenge',
                            challenge: 'new_password',
                            session: response.Session
                        };
                    } else {
                        throw new Error('Unsupported challenge ' + challenge);
                    }

                    // All of the following challenges require USERNAME and SECRET_HASH (if applicable) in the parameters.
                    // SMS_MFA, PASSWORD_VERIFIER, CUSTOM_CHALLENGE, DEVICE_SRP_AUTH, DEVICE_PASSWORD_VERIFIER, NEW_PASSWORD_REQUIRED, MFA_SETUP

                    // NEW_PASSWORD_REQUIRED
                    /*
                    Respond to this challenge with NEW_PASSWORD and any required attributes that Amazon Cognito returned in the requiredAttributes parameter. You can also set values for attributes that aren't required by your user pool and that your app client can write. For more information, see RespondToAuthChallenge.
                    In a NEW_PASSWORD_REQUIRED challenge response, you can't modify a required attribute that already has a value. In RespondToAuthChallenge, set a value for any keys that Amazon Cognito returned in the requiredAttributes parameter, then use the UpdateUserAttributes API operation to modify the value of any additional attributes.
                     */
                }

                const now = Date.now()
                const expiresIn = response.AuthenticationResult.ExpiresIn;
                const tokenExpiresTimestamp = (expiresIn*1000) + now;
                const auth_tokens = {
                    TokenExpires: tokenExpiresTimestamp,
                    AccessToken: response.AuthenticationResult.AccessToken,
                    IdToken: response.AuthenticationResult.IdToken,
                    RefreshToken: response.AuthenticationResult.RefreshToken
                };

                // set storage
                localStorage.setItem('auth_tokens', JSON.stringify(auth_tokens));
                await context.commit("setAuthorizationTokens", auth_tokens);
                return {
                    code: 'authenticated'
                };
            } catch (error) {
                console.log('initiateAuth error: ' + JSON.stringify(error, null, 2));
                throw error;
            }
        },
        async login(context, payload) {
            try {
                const result = await context.dispatch('initiateAuth', payload);
                if ('authenticated' === result.code) {
                    // const auth_tokens = await context.getters.getAuthorizationTokens;
                    // await context.dispatch('_fetchCurrentUser', auth_tokens.AccessToken)
                    return {
                        result: 'authenticated'
                    }
                } else if ('challenge' === result.code) {
                    console.log('challenge result: ' + JSON.stringify(result));
                    return {
                        result: 'new_password',
                        session: result.session
                    }
                }
            } catch (error) {
                if (error instanceof UserNotConfirmedException) {
                    return {
                        result: 'confirmIdentity'
                    };
                } else {
                    console.log('login error: ' + JSON.stringify(error, null, 2));
                    throw error;
                }
            }
        },
        async signUp(context, payload) {
            try {
                // API https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_SignUp.html
                // javascript https://docs.aws.amazon.com/AWSJavaScriptSDK/v3/latest/client/cognito-identity-provider/command/SignUpCommand/
                const response = await cognitoUserPoolClient.send(new SignUpCommand({
                    ClientId: config.clientId,
                    Username: payload.username,
                    Password: payload.password,
                    // UserAttributes: [{ Name: "email", Value: payload.email }],
                }));
                // console.log('signup response: ' + JSON.stringify(response))

                return response.UserConfirmed;
            } catch (error) {
                console.log('signUp error: ' + JSON.stringify(error, null, 2));
                throw error;
            }
        },
        async confirmSignUp(_, payload) {
            try {
                // API https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_ConfirmSignUp.html
                // javascript: https://docs.aws.amazon.com/AWSJavaScriptSDK/v3/latest/client/cognito-identity-provider/command/ConfirmSignUpCommand/
                await cognitoUserPoolClient.send(new ConfirmSignUpCommand({
                    ClientId: config.clientId,
                    Username: payload.username,
                    ConfirmationCode: payload.code,
                }));
                /*console.log(JSON.stringify(response));*/
            } catch (error) {
                console.log('confirmSignUp error: ' + JSON.stringify(error, null, 2));
                throw error;
            }
        },
        async logout(context) {
            try {
                // API https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_GlobalSignOut.html
                // javascript https://docs.aws.amazon.com/AWSJavaScriptSDK/v3/latest/client/cognito-identity-provider/command/GlobalSignOutCommand/
                const authTokens = await context.getters.getAuthorizationTokens
                if (authTokens != null && authTokens.AccessToken != null) {
                    await cognitoUserPoolClient.send(new GlobalSignOutCommand({
                        AccessToken: authTokens.AccessToken
                    }));
                }
            } catch (error) {
                console.log('logout error: ' + JSON.stringify(error, null, 2));
            }

            await context.dispatch("_clearCredentials");
        },
        async _clearCredentials(context) {
            /*await context.commit("setUser", null);*/
            await context.commit("setAuthorizationTokens", null);
            localStorage.removeItem('auth_tokens');
            // localStorage.removeItem('user');
        },
        async newPasswordChallenge(context, payload) {
            /*
                "ChallengeName": "NEW_PASSWORD_REQUIRED",
                "ChallengeParameters": {
                    "USER_ID_FOR_SRP": "c939b90e-2021-706b-cff9-ca07acf9c05a",
                    "requiredAttributes": "[]",
                    "userAttributes": "{\"email\":\"julia@j2clark.net\"}"
                },
                "Session": "AYABeHgEDcNHgh5IplJGuW_rbOIAHQABAAdTZXJ2aWNlABBDb2duaXRvVXNlclBvb2xzAAEAB2F3cy1rbXMAS2Fybjphd3M6a21zOnVzLXdlc3QtMTowMzM4NzYwMTA3Njc6a2V5Lzk2YmZkMjRjLWRkYWYtNDM5MC05OWQ4LTE2ODAwNjE0ZDg4OAC4AQIBAHjeYRmxoCL-0GSaGs4rLclbJTmSogOUuu-elmOHP7IVcgG9TaviVcZ2u1wLCcd9XVCMAAAAfjB8BgkqhkiG9w0BBwagbzBtAgEAMGgGCSqGSIb3DQEHATAeBglghkgBZQMEAS4wEQQMmN43QA2FjXERHbt8AgEQgDs2vHajuu7OYeBJXB3s8ssmSchk8YV4KYGLwMg4LNWg9T7NH2SKeNRdbhdlB3s3X_GKAsAEk9YZcfQ2TgIAAAAADAAAEAAAAAAAAAAAAAAAAABnG0s0omAOUh9Mhv8zq_hP_____wAAAAEAAAAAAAAAAAAAAAEAAADVvZBu-faJZHKmipvMSgXYSangSTZZvIIM-ZWEiyV0NvD5-Rp6OltFfUUiRT06Tw_7QX1Ch0CNOVBrnkfYQJnxpQR5B5-5G1L-k17a6bwKKbXRo9OjLzthp6YMyC7jScWXiKSXOib6kpKJo7CFOKTlx-w_02p-YFWf3nFveeMxoWSd9k8emIT1UZk4ya_9fRp4EDti9-YE6ECw-zlTiaZnc_7Jd0S_OQ0dO1dyKji-eHyI9BY1svnLHV886tJgZvgME8FRLOkffF1T3dzClM9PcUWo2tHMWjpzUq_gfukNwpbHQjSybg"
             */
            try {
                // response is same as initiateAuth
                const response = await cognitoUserPoolClient.send(new RespondToAuthChallengeCommand({
                    ClientId: config.clientId,
                    ChallengeName: "NEW_PASSWORD_REQUIRED",
                    ChallengeResponses: {
                        "NEW_PASSWORD": payload.newPassword,
                        "USERNAME": payload.username
                    },
                    Session: payload.session
                }));

                const challenge = response.ChallengeName;
                if (challenge) {
                    throw new Error('Nested Challenge not supported');
                } else {
                    // this is same as in initAuth
                    const now = Date.now()
                    const expiresIn = response.AuthenticationResult.ExpiresIn;
                    const tokenExpiresTimestamp = (expiresIn*1000) + now;
                    const auth_tokens = {
                        TokenExpires: tokenExpiresTimestamp,
                        AccessToken: response.AuthenticationResult.AccessToken,

                        // TODO: these stay the same - remove from AuthTokens
                        IdToken: response.AuthenticationResult.IdToken,
                        RefreshToken: response.AuthenticationResult.RefreshToken
                    };

                    // set storage
                    localStorage.setItem('auth_tokens', JSON.stringify(auth_tokens));
                    await context.commit("setAuthorizationTokens", auth_tokens);
                    return {
                        code: 'authenticated'
                    };
                }
            } catch (error) {
                console.log('newPasswordChallenge error: ' + JSON.stringify(error, null, 2));
                throw error;
            }
        }
    },

}