<template>
  <div>
    <base-dialog :show="!!error" title="An error occurred" @close="handleError">
      <p>{{ error.message }}</p>
    </base-dialog>
    <base-dialog fixed :show="isLoading" title="Authenticating...">
      <base-spinner></base-spinner>
    </base-dialog>
    <form v-if="!needsConfirmation && !needsPassword" class="flex flex-col items-center" @submit.prevent="submitForm">
      <div class="flex flex-col user">
        <label class="block text-gray-700 text-sm font-bold mb-2" for="username">Email</label>
        <input
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight"
            type="email"
            v-model="username"
            id="username"
        />
      </div>
      <div class="flex flex-col mt-10">
        <label class="block text-gray-700 text-sm font-bold mb-2" for="password">Password</label>
        <input
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight"
            type="password"
            v-model="password"
            id="password"
        />
      </div>
      <div class="text-red-600" v-if="error">{{ error.message }}</div>
      <button class="btn-blue mt-2">Log in</button>
      <button type="button" class="btn-blue-outline mt-2" @click="signup">Sign Up Instead</button>
    </form>
    <div v-if="needsConfirmation" class="flex flex-col items-center">
      <h3>Enter your code. Please check your email</h3>
      <div class="flex flex-col mt-2">
        <label class="block text-gray-700 text-sm font-bold" for="code">Code</label>
        <input
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight"
            type="text"
            v-model.trim="code"
            id="code"
        />
      </div>
      <button class="btn-blue mt-2" @click="confirmCode">Confirm Code</button>
      <button class="btn-blue-outline mt-2" @click="requestNewCode">Request New Code</button>
      <p v-if="!!codeSentTo">{{ codeSentTo }}</p>
    </div>

    <div v-if="needsPassword" class="flex flex-col items-center">
      <h3>Enter a new Password</h3>
      <div class="flex flex-col mt-2">
        <label class="block text-gray-700 text-sm font-bold" for="code">Password</label>
        <input
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight"
            type="text"
            v-model.trim="newPassword"
            id="code"
        />
      </div>
      <button class="btn-blue mt-2" @click="changePassword">Change Password</button>
    </div>

  </div>
</template>


<script>
import BaseDialog from "@/components/BaseDialog.vue";
import BaseSpinner from "@/components/BaseSpinner.vue";
// import {mapActions} from "vuex";

export default {
  components: {
    BaseDialog,
    BaseSpinner,
  },
  data() {
    return {
      username: '',
      password: '',
      newPassword: '',
      isLoading: false,
      needsConfirmation: false,
      needsPassword: false,
      needsPasswordSession: null,
      code: '',
      codeSentTo: '',
      error: null
    }
  },
  methods: {
    // ...mapActions({
    //   'loginVue': 'auth/login'
    // }),
    async submitForm() {
      this.error = null;
      if (this.username === '' || this.password === '') {
        this.error = {
          message: 'Please enter a valid email and password'
        }
        return;
      }

      this.isLoading = true;
      this.$store.dispatch('auth/login', {
            username: this.username,
            password: this.password
          }
      )
      .then(result => {
        console.log('singIn result: ' + JSON.stringify(result));
        this.isLoading = false;
        //  todo: we need structured data returned. In case of challenges we have additional data
        if (result.result === 'authenticated') {
          // const redirectUrl = '/' + (this.$route.query.redirect || 'albums')
          const redirectUrl = '/' + (this.$route.query.redirect || 'polly')
          console.log('redirecting to ' + redirectUrl);
          this.$router.replace(redirectUrl)
        } else if (result.result === 'confirmIdentity') {
          this.needsConfirmation = true;
        } else if (result.result === 'new_password') {
          this.needsPassword = true;
          this.needsPasswordSession = result.session

          console.log('needsPassword' + this.needsPassword);
          console.log('needsPasswordSession' + this.needsPasswordSession);
        } else {
          this.error = {message: 'unexpected result ' + result};
        }
      })
      .catch(error => {
        this.isLoading = false;
        this.error = {message: error.message};
      })
    },
    async requestNewCode() {
      this.codeSentTo = null;
      this.isLoading = true;
      this.$store.dispatch('auth/requestCode', {username: this.username})
          .then(resp => {
            //{"destination":"j***@g***","deliveryMedium":"EMAIL","attributeName":"email"}
            this.codeSentTo = 'Code sent to ' + resp.destination;
          })
          .catch(err => {
            console.log(err);
            this.error = err;
          })

      this.isLoading = false;
    },
    async confirmCode() {
      this.codeSentTo = null;
      if (this.code === '') {
        this.error = {
          message: 'Code is required. Check your email'
        }
        return;
      }

      try {
        this.isLoading = true;
        await this.$store.dispatch('auth/confirmSignUp', {
          username: this.username,
          code: this.code
        })

        await this.$store.dispatch('auth/login', {
          username: this.username,
          password: this.password,
        })

        // this.$router.push('/albums')
        this.$router.push('/polly')
      } catch (error) {
        console.log(error)
        //  todo: refine error message
        this.error = error;
      }

      this.isLoading = false;
    },
    async changePassword() {
      // this.codeSentTo = null;
      if (this.newPassword === '') {
        this.error = {
          message: 'New Password is required.'
        }
        return;
      }

      /*try {*/
        this.isLoading = true;

        const payload = {
          username: this.username,
          newPassword: this.newPassword,
          session: this.needsPasswordSession
        }
        console.log('payload ' + JSON.stringify(payload))

        this.$store.dispatch('auth/newPasswordChallenge', payload).then(() => {
          this.$store.dispatch('auth/login', {
            username: this.username,
            password: this.newPassword,
          }).then(() => {
            // this.$router.push('/albums')
            this.$router.push('/polly')
          })
        }).catch((error) => {
          console.log(error)
          this.error = error
        })

        /*await this.$store.dispatch('auth/login', {
          username: this.username,
          password: this.newPassword,
        })

        this.$router.push('/albums')*/
      /*} catch (error) {
        console.log(error)
        //  todo: refine error message
        this.error = error;
      }*/

      this.isLoading = false;
    },
    signup() {
      this.$router.replace('/signup')
    },
    handleError() {
      this.error = null
    }
  }
}
</script>
