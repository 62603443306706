<template>
  <div>
    <base-dialog :show="!!error" title="An error occurred" @close="handleError">
      <p>{{ error.message }}</p>
    </base-dialog>
    <base-dialog fixed :show="isLoading" title="Authenticating...">
      <base-spinner></base-spinner>
    </base-dialog>
    <form
        v-if="!needsConfirmation"
        class="flex flex-col items-center"
        @submit.prevent="signUp"
    >
      <div class="flex flex-col user">
        <label
            class="block text-gray-700 text-sm font-bold mb-2"
            for="username">
          Email
        </label>
        <input
            class="shadow appearance-none border rounded w-full py-2 px-3 leading-tight"
            type="email"
            v-model.trim="username"
            id="username"
        />
      </div>
      <div class="flex flex-col mt-2">
        <label
            class="block text-gray-700 text-sm font-bold mb-2"
            for="password">
          Password
        </label>
        <input
            class="shadow appearance-none border rounded w-full py-2 px-3 leading-tight"
            type="password"
            v-model.trim="password"
            id="password"
        />
      </div>
<!--      <div class="flex flex-col mt-2">
        <label
            class="block text-gray-700 text-sm font-bold mb-2"
            for="email">
          Email
        </label>
        <input
            class="shadow appearance-none border rounded w-full py-2 px-3 leading-tight"
            type="email"
            v-model.trim="email"
            id="email"
        />
      </div>-->
<!--      <div v-if="error" class="text-red-600">{{ error.message }}</div>-->
      <button class="btn-blue mt-2">Sign Up</button>
      <button type="button" class="btn-blue-outline mt-2" @click="signin">Login Instead</button>
    </form>
    <div v-if="needsConfirmation" class="w-4/12 m-auto">
      <h3>Enter your code. Please check your email</h3>
      <div class="flex flex-col mt-2">
        <label class="block text-gray-700 text-sm font-bold" for="code">Code</label>
        <input
            class="shadow appearance-none border rounded w-full py-2 px-3 leading-tight"
            type="text"
            v-model.trim="code"
            id="code"
        />
      </div>
      <div v-if="error" class="text-red-600">{{ error.message }}</div>
      <button class="btn-blue mt-2" @click="confirmSignup">Confirm Code</button>
    </div>
  </div>
</template>

<script>
import BaseDialog from "@/components/BaseDialog.vue";
import BaseSpinner from "@/components/BaseSpinner.vue";

export default {
  components: {
    BaseSpinner,
    BaseDialog,
  },
  data() {
    return {
      username: '',
      password: '',
      // email: '',
      needsConfirmation: false,
      code: '',
      isLoading: false,
      error: null
    }
  },
  methods: {
    signin() {
      this.$router.replace('/signin');
    },
    async signUp() {
      this.error = null;
      if (!this.username.includes('@') || this.password.length < 6 /*|| !this.email.includes('@')*/) {
        this.error = {
          message: 'Please enter a valid email address and password (must be at least 6 characters)'
        }
        return;
      }

      try {
        this.isLoading = true;
        const confirmed = await this.$store.dispatch("auth/signUp", {
          username: this.username,
          password: this.password,
          /*email: this.email*/
        });

        /*console.log('signup page: result: ' + result);*/

        this.needsConfirmation = !confirmed;
      } catch (error) {
        //  todo: refine error message
        this.error = error;
      }

      this.isLoading = false;
    },
    async confirmSignup() {
      this.error = null;
      if (this.code === '') {
        this.error = {
          message: 'Confirmation code is required. Check your email'
        }
        return;
      }

      try {
        this.isLoading = true;
        await this.$store.dispatch("auth/confirmSignUp", {
          username: this.username,
          code: this.code
        });

        await this.$store.dispatch('auth/login', {
           username: this.username,
           password: this.password,
        })

        // this.$router.push('/albums')
        this.$router.push('/polly')
      } catch (error) {
        console.log(error)
        //  todo: refine error message
        this.error = error;
      }

      this.isLoading = false;
    },
    handleError() {
      this.error = null
    }
  }
}
</script>
