<template>
  <nav>
    <nav-header></nav-header>
  </nav>
  <router-view/>
</template>

<script>
import NavHeader from "@/components/NavHeader.vue";

export default {
  components: {
    NavHeader
  },
  created() {
    console.log('AppView.created')
    this.$store.dispatch('auth/tryLogin')
        .then(() => {
          // const redirectUrl = '/' + (this.$route.query.redirect || 'albums')
          const redirectUrl = '/' + (this.$route.query.redirect || 'polly')
          console.log('AppView.created: redirect to ' + redirectUrl)
          this.$router.replace(redirectUrl)
        })
        .catch((error) => {
          console.log(error)
          this.$router.replace('/')
        })
  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
