<template>
  <div>
    <base-dialog :show="!!error" title="An error occurred" @close="handleError">
      <p>{{ error.message }}</p>
    </base-dialog>
    <base-dialog fixed :show="isLoading" title="Loading...">
      <base-spinner></base-spinner>
    </base-dialog>

    <h3>Chat: {{ conversation.name }}</h3>
    <div class="flex flex-col m-auto w-64">
      <input class="my-4 bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700"
             placeholder="Message ..."
             type="text"
             v-model="message"/>
      <button class="btn-blue mb-4" @click="postMessage">Post</button>

      <ul>
        <li v-for="m in conversation.messages" :key="m.id">{{m.sender}}: {{m.message}}</li>
      </ul>


    </div>
    <h5 class="mt-4 text-red-500" v-if="error">{{ error }}</h5>
  </div>
</template>

<script>
import axios from "axios";
import BaseDialog from "@/components/BaseDialog.vue";
import BaseSpinner from "@/components/BaseSpinner.vue";

export default {
  components: {
    BaseDialog,
    BaseSpinner,
  },
  data() {
    return {
      conversation: {
        id: this.$route.params.id,
        participants: [],
        messages: [
          {
            sender: '',
            time: new Date().getMilliseconds(),
            message: 'New Conversation'
          }
        ]
      },
      // todo: reload messages every X seconds, using last chat as variable
      // the timestamp of last message, for efficiency on reloading
      // lastChat: null,
      message: '',
      isLoading: false,
      error: '',
    }
  },
  methods: {
    async postMessage() {
      const chatId = this.$route.params.id
      this.error = ""
      this.isLoading = true;

      const content = {
        message: this.message
      };

      const apiPath = '/conversations/' + chatId
      const headers = await this.$store.dispatch('auth/signPOST', {
        apiPath: apiPath,
        body: content
      });

      const url = process.env.VUE_APP_API_URL + apiPath
      await axios.post(url, content, {
        headers: headers
      }).then(response => {
        this.message = ''
        const data = response.data
        console.log('data: ' + JSON.stringify(data));

        //  append to message body
        // TODO: this.conversation.messages.push()
        // this.isLoading = false
        this.fetch()
      }).catch(err => {
        this.isLoading = false
        console.log(err)
        this.error = err.message;
      });
    },
    handleError() {
      this.error = null
    },
    async fetch() {
      const chatId = this.$route.params.id
      this.error = ""
      this.isLoading = true;

      const apiPath = '/conversations/' + chatId;
      const headers = await this.$store.dispatch('auth/signGET', apiPath);

      const url = process.env.VUE_APP_API_URL + apiPath
      console.log('GET url: ' + url);
      await axios.get(url, {
        headers: headers
      }).then(response => {
        this.isLoading = false

        const data = response.data
        console.log('data: ' + JSON.stringify(data));
        // console.log('data type: ' + Object.prototype.toString.call(data));
        this.conversation = data
      }).catch(err => {
        this.isLoading = false
        console.log(err)
        this.error = err.message;
      });
    }
  },
  async mounted() {
    await this.fetch()
  }
}
</script>