// https://dev.to/aws-builders/a-simple-way-to-sign-aws-requests-with-signature-v4-9eo
export const albumInfo = {
    namespaced: true,
    state: {
        error: null,
        albums: null
    },
    getters: {
        albums: (state) => state.albums
    },
    mutations: {
        setAlbums(state, payload) {
            state.albums = payload
        }
    },
    actions: {
        /*async createAlbum(context, newAlbum) {

            console.log('createAlbum: ' + JSON.stringify(newAlbum))

            // const client = generateClient()
            // try {
            //     /!*const response =*!/
            //         await client.graphql({
            //         query: createAlbumMutation,
            //         variables: {
            //             input: newAlbum
            //         }
            //     })
            //
            //     // console.log('created response: ' + JSON.stringify(response))
            //     // const item = response.data.createAlbum
            //     // console.log('createdAlbum: ' + JSON.stringify(item))
            //
            //     // const items = context.getters['albums'];
            //     // // console.log('loadedAlbums ' + JSON.stringify(items))
            //     //
            //     // const albums = [...items]
            //     // albums.push(item)
            //     //
            //     // context.commit("setAlbums", albums)
            //     context.dispatch('loadAlbums')
            // } catch (error) {
            //     console.log('createAlbum', error)
            //     throw error
            // }
            throw new Error("TBD")
        },*/
        /*async getAlbum(context, albumId) {
            console.log(context)
            console.log(albumId)
            // const client = generateClient()
            // const response = await client.graphql({
            //     query: getAlbumQuery,
            //     variables: {
            //         id: albumId
            //     }
            // })
            // // console.log('album: ' + JSON.stringify(response.data.getAlbum))
            //
            // return response.data.getAlbum;
            throw new Error("TBD")
        },*/
        /*async loadAlbums(context) {
            console.log(context)

            // const awsCredentials = this.$store.dispatch('auth/awsCredentials')
            // console.log(JSON.stringify(awsCredentials))
            // const signedFetch = createSignedFetcher({
            //     service: 'execute-api',
            //     region: config.region,
            //     credentials: awsCredentials
            // })
            // const url = 'https://mygraphqlapi.appsync-api.eu-west-1.amazonaws.com/graphql';
            //
            // const body = { a: 1 };
            //
            // const response = await signedFetch(url, {
            //     method: 'post',
            //     body: JSON.stringify(body),
            //     headers: {'Content-Type': 'application/json'}
            // });
            //
            // const data = await response.json();
            // console.log(JSON.stringify(data))

            // const service = 'execute-api';
            // const host = config.RESTAPIHOST;
            // const canonicalURI = config.RESTAPIPATH;
            // const region = config.region;
            //
            // const options = {
            //     hostname: host,
            //     path: canonicalURI,
            //     method: 'GET',
            //     headers: {
            //         'Host': host,
            //     },
            // };
            //
            // const creds  = await this.$store.dispatch('auth/awsCredentials')
            // const signer = aws4.sign({
            //     service: service,
            //     region: region,
            //     path: canonicalURI,
            //     headers: options.headers,
            //     method: options.method,
            //     body: '',
            // }, creds);
            //
            // Object.assign(options.headers, signer.headers);

            // const req = https.request(options, (res) => {
            //     console.log(`response Status: ${res.statusCode}`);
            //
            //     res.on('data', (chunk) => {
            //         console.log(`response Body: ${chunk}`);
            //     });
            // });

            // const client = generateClient()
            // const loadedAlbums = await client.graphql({query: listAlbumsQuery})
            //
            // const items = loadedAlbums.data.listAlbums.items;
            // // console.log('loadedAlbums ' + JSON.stringify(items))
            const albums = [];



            // for (let i = 0; i < items.length; i++) {
            //     // console.log('loaded['+i+'] ' + JSON.stringify(items[i]))
            //     albums.push(items[i])
            // }
            //
            // context.commit("setAlbums", albums);
            return albums
            // return Promise.reject(new Error("TBD"));
        },*/
        /*async createPhoto(_, data) {
            console.log(data)
            /!*!// console.log('photo: ' + JSON.stringify(data))
            const {
                file,
                type: mimeType,
                albumId: albumId
            } = data;
            // const {type: mimeType} = data
            // const albumId = data.albumId
            console.log('photo file mimeType: ' + mimeType)

            const extension = file.name.substr(file.name.lastIndexOf(".") + 1);
            const photoId = uuidv4();
            const key = 'images/'+photoId+'.'+extension;


            // s3 storage
            try {
                const {
                    aws_user_files_s3_bucket_region: region,
                    aws_user_files_s3_bucket: bucket
                } = awsconfig
                // const result =
                const uploadResult = await uploadData({
                    key: key,
                    data: file,
                    options: {
                        accessLevel: 'protected',
                        contentType: mimeType,
                        metadata: {
                            albumId: albumId,
                            photoId: photoId
                        }
                    }
                }).result; // <= this is what makes the task actually wait until completion?
                console.log('uploadResult: ' + JSON.stringify(uploadResult))

                const client = generateClient()
                const inputData = {
                    id: photoId,
                    contentType: mimeType,
                    fullsize: {
                        key: key,
                        region: region,
                        bucket: bucket
                    },
                }
                await client.graphql({
                        query: createPhotoMutation,
                        variables: {
                            input: {
                                ...inputData,
                                albumPhotosId: albumId,
                            }
                        }
                })

                return Promise.resolve(inputData);
            } catch (error) {
                console.log(error)
                return Promise.reject(error)
            }*!/
            throw new Error("TBD")
        }*/
    }
}