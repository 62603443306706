<template>
  <div v-if="!!user">
    <h3>Welcome {{ user.username }}</h3>
  </div>
  <div v-else>
    <h3>Hello World</h3>
  </div>
</template>

<script>
export default {
  computed: {
    user() {
      return this.$store.getters['auth/user']
    }

  }
}
</script>