<template>
  <div>
    <base-dialog :show="!!error" title="An error occurred" @close="handleError">
      <p>{{ error }}</p>
    </base-dialog>
    <base-dialog fixed :show="isLoading" title="Loading...">
      <base-spinner></base-spinner>
    </base-dialog>
    <h3 class="text-4xl font-semibold">Album {{  albumName }}</h3>
    <div class="flex w-full mt-10 items-center justify-center bg-grey-lighter">
      <form enctype="multipart/form-data" novalidate>
        <label
          class="w-64 flex flex-col items-center px-4 py-6 bg-white text-blue rounded-lg shadow-lg tracking-wide">
          <cloud-arrow-up-icon class="w-8 h-8 text-blue-500" />
          <span class="mt-2 text-base leading-normal">Select a file</span>
          <input @change="onFileChange"
                 accept="image/*"
                 type="file"
                 class="hidden"
                 multiple="multiple"
          />
        </label>
      </form>
    </div>
    <div class="text-2x mt-4">List of Photos</div>
    <div class="flex flex-wrap p-10 justify-center m-auto w-full" v-if="photos">
      <div class="shadow-xl ml-4 mt-4 w-4/12" v-for="photo in photos" :key="photo.photoId" >
        <img
            class="object-scale-down max-w-full drop-shadow-md rounded-md m-auto"
            style="width: 75%"
            :src="photo.url"
            :alt="photo.fileName"
        />
        <p>{{photo.fileName}}</p>
<!--
        <div v-if="photo.createdAt && photo.gps">
          <ul>
            <li>Created At {{ photo.createdAt }}</li>
            <li>
              latitude {{ photo.gps.latitude }}
            </li>
            <li>
              longitude {{ photo.gps.longitude }}
            </li>
          </ul>
        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
// https://heroicons.com/solid
// https://github.com/tailwindlabs/heroicons
import { CloudArrowUpIcon } from '@heroicons/vue/24/solid';
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import {
  S3Client,
  GetObjectCommand
} from "@aws-sdk/client-s3";
import { Upload } from "@aws-sdk/lib-storage";
import {config} from "@/config";
import axios from "axios";
import BaseDialog from "@/components/BaseDialog.vue";
import BaseSpinner from "@/components/BaseSpinner.vue";

export default {
  components: {
    BaseDialog,
    BaseSpinner,
    CloudArrowUpIcon
  },
  data() {
    return {
      albumId: null,
      albumName: '',
      photos: [],

      error: '',
      isLoading: false
    }
  },
  methods: {
    async s3Client() {
      const credentials = await this.$store.dispatch('auth/fetchAWSCredentials');
      return new S3Client({
        region: config.region,
        credentials:  {
          accessKeyId: credentials.AccessKeyId,
          secretAccessKey: credentials.SecretKey,
          sessionToken: credentials.SessionToken
        }
      });
    },
    async onFileChange(files) {
      if (!files.target || !files.target.files[0]) {
        //  no files selected
        return;
      }

      try {
        this.isLoading = true;
        // https://stackoverflow.com/questions/69851393/cognito-authenticated-role-via-lambda-to-getobject-an-error-occurred-accessde
        // https://aws.amazon.com/blogs/mobile/understanding-amazon-cognito-authentication-part-3-roles-and-policies/

        // const imageFiles = []
        const imageFiles = {}
        // filter out non-supported image files
        for (const file of files.target.files) {
          const {type: mimeType} = file
          if (mimeType === 'image/jpeg' || mimeType === 'image/png') {
            imageFiles[file.name] = file
          } else {
            console.log('Unsupported image [' + file.name + ']: ' + mimeType)
            this.error = 'Unsupported image [' + file.name + ']';
            return;
          }
        }

        if (imageFiles.length < 1) {
          this.error = 'no image files found';
          return;
        }

        // Multiple Steps
        // 1. Post new record to API. AlbumId, Filename (no path). Responds with PhotoId
        // 2. Upload file to S3, using PhotoId: Path = photos/identityId/albumId/photoId
        // 3. Get signedUrl for newly uploaded file and add to list

        const photoItems = []
        for (const filename in imageFiles) {
          // console.log('filename: ' + filename)
          photoItems.push({
            filename: filename
          });
        }

        /*
        upload schema to API:
        [
          {
            albumId: {type: string},
            name: {type: string}
          }
        ]

        API Returns
        [
          {
            albumId: {type: string},
            photoId: {type: string},
            name: {type: string}
            imageUrl: {type: signedUrl, default: null}
            thumbnailUrl: {type: signedUrl, default: null}
          }
        ]
         */

        const content = {
          photos: photoItems
        }

        const apiPath = '/albums/' + this.albumId;
        const headers = await this.$store.dispatch('auth/signPOST', {
          apiPath: apiPath,
          body: content
        });

        const url = process.env.VUE_APP_API_URL + apiPath;
        const apiResponse = await axios.post(url, content, {
          headers: headers
        });
        // console.log('apiResponse: ' + JSON.stringify(apiResponse));

        const federatedIdentityId = await this.$store.dispatch('auth/fetchFederatedIdentityId');
        const s3Client = await this.s3Client();

        const createdPhotos = apiResponse.data
        for (const newPhoto of createdPhotos) {
          const photoId = newPhoto.photoId
          const filename = newPhoto.fileName

          const file = imageFiles[filename]
          // console.log('filename: ' + filename)

          const extension = filename.substr(filename.lastIndexOf(".") + 1).toLowerCase();
          const key = 'photos/' + federatedIdentityId + '/' + this.albumId + '/' + photoId + '.' + extension;
          const parallelUploads3 = new Upload({
            client: s3Client,
            tags: [
               { Key: "OriginalFileName", Value: filename }
            ],
            queueSize: 4, // optional concurrency configuration
            leavePartsOnError: false, // optional manually handle dropped parts
            params: {
              Bucket: config.data_bucket,
              Key: key,
              Body: file
            },
          });

          // TODO: Add progress to loading dialog
          //   parallelUploads3.on("httpUploadProgress", (/*progress*/) => {
          //     // TODO: Let user know about progress
          //     //  console.log(progress);
          //   });

          await parallelUploads3.done();
          console.log("file uploaded: " + JSON.stringify(filename))

          /*
          TODO: How to update photos without reloading? VueJS memory model

          const command = new GetObjectCommand({
            Bucket: config.data_bucket,
            Key: key
          });
          const url = await getSignedUrl(
            this.s3Client,
            command,
            {
              expiresIn: 3600
            }
          )

          this.photos.push({
            albumId: this.albumId,
            photoId: photoId,
            name: filename,
            imageUrl: url,
            thumbnailUrl: null
          });*/
        }

        // this.isLoading = false;
        await this.loadPhotos();
      } catch (error) {
        this.error = error;
        console.log(error);
        this.isLoading = false;
      }
    },
    async loadPhotos() {
      /*
      API returns a list of photos for a given collectionId, with the following schema:
       {
        photoId: {type: string},
        albumId: {type: string},
        name: {type: string},
        imageUrl: {type: signedUrl}
        thumbnailUrl: {type: signedUrl}
       }
       */

      this.error = ""
      this.isLoading = true;

      const federatedIdentityId = await this.$store.dispatch('auth/fetchFederatedIdentityId');
      const s3Client = await this.s3Client();

      // TODO: figure out how to manipulate array in place
      this.photos = [];

      try {
        const apiPath = '/albums/' + this.albumId
        const headers = await this.$store.dispatch('auth/signGET', apiPath)

        const url = process.env.VUE_APP_API_URL + apiPath
        const response = await axios.get(url, {
          headers: headers
        });

        const photos = []
        for (const photo of response.data) {
          // console.log("photo: " + JSON.stringify(photo))
          let url = photo.thumbnailUrl;
          /*if (photo.thumbnailUrl !== null) {
            console.log('thumbail: ' + url)
            url = photo.thumbnailUrl
          }*/

          if (url == null) {
            // console.log('thumbail: ' + url)
            url = photo.imageUrl
          }

          if (url == null) {
            // console.log('fullsize: ' + url)
            const extension = photo.fileName.substr(photo.fileName.lastIndexOf(".") + 1).toLowerCase();

            const key = 'photos/' + federatedIdentityId + '/' + this.albumId + '/' + photo.photoId + '.' + extension;
            // const key = 'photos/' + this.identityId + '/' + this.albumId + '/' + photo.photoId + '.' + extension;
            const command = new GetObjectCommand({
              Bucket: config.data_bucket,
              Key: key
            });
            // url = await getSignedUrl(this.s3Client, command, { expiresIn: 3600 })
            url = await getSignedUrl(s3Client, command, { expiresIn: 3600 })
            console.log('signedUrl: ' + url)
          }

          const photoEntry = {
            albumId: this.albumId,
                photoId: photo.photoId,
                name: photo.fileName,
                url: url
          }
          // console.log("push photo: " + JSON.stringify(photoEntry))
          photos.push(photoEntry)
        }
        this.photos = photos
        this.isLoading = false;
      } catch (err) {
          console.log(err)
          this.error = err.message;
          this.isLoading = false
      }
    },
    handleError() {
      this.error = null
    }
  },
  async mounted() {
    this.albumId = this.$route.params.id
    await this.loadPhotos();
  },
}
</script>
