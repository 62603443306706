<template>
  <div>
    <base-dialog :show="!!error" title="An error occurred" @close="handleError">
      <p>{{ error.message }}</p>
    </base-dialog>
    <base-dialog fixed :show="isLoading" title="Loading...">
      <base-spinner></base-spinner>
    </base-dialog>
    <h3>New Chat</h3>
    <div class="flex flex-col m-auto w-64">
      <ul>
        <li v-for="user in users" :key="user.id">
          <button class="btn-blue mb-4" @click="createConversation(user)">{{ user.handle }}</button>
<!--          <router-link :to="{name: 'ChatDetail', params: {id: conversation.id }}">{{ conversation.participants }}: {{moment(conversation.last)}}</router-link>-->
        </li>
      </ul>
    </div>
<!--    <ul>-->
<!--      <li v-for="p in people" :key="p.name">{{ p.name }}</li>-->
<!--    </ul>-->
  </div>
</template>
<script>
import axios from "axios";
import BaseDialog from "@/components/BaseDialog.vue";
import BaseSpinner from "@/components/BaseSpinner.vue";

export default {
  components: {
    BaseDialog,
    BaseSpinner,
  },
  data() {
    return {
      users: [],
      isLoading: false,
      error: '',
    }
  },
  methods: {
    handleError() {
      this.error = null
    },
    async fetch() {
      this.error = ""
      this.isLoading = true;

      const apiPath = '/users';
      const headers = await this.$store.dispatch('auth/signGET', apiPath);

      const url = process.env.VUE_APP_API_URL
      axios.get(url + '/users', {
        headers: headers
      }).then(response => {
        this.isLoading = false

        const data = response.data;
        console.log('users data: ' + JSON.stringify(data));
        // console.log('data type: ' + Object.prototype.toString.call(data));
        // this.conversations = JSON.parse(data);
        this.users = data;
      }).catch(err => {
        this.isLoading = false
        console.log(err)
        this.error = err.message;
      });
    },
    async createConversation(user) {
      console.log(JSON.stringify(user));

      this.error = ""
      this.isLoading = true;

      const users = []
      users.push(user.id)

      const content = {
        users: users
      }

      const apiPath = '/conversations'
      const headers = await this.$store.dispatch('auth/signPOST', {
        apiPath: apiPath,
        body: content
      });

      const url = process.env.VUE_APP_API_URL + apiPath
      await axios.post(url, content,{
        headers: headers
      }).then(response => {
          this.isLoading = false;
          const data = response.data
          // console.log('data: ' + JSON.stringify(data));

          const chatId = data.id;
          // console.log('chatId: ' + JSON.stringify(chatId));

          this.$router.replace("/chats/" + chatId);
      }).catch(err => {
        this.isLoading = false
        console.log(err)
        this.error = err.message;
      });
    }
  },
  async mounted() {
    await this.fetch()
  }
}
</script>