<template>
  <div>
    <ul class="flex justify-end">
      <li class="mr-6" v-if="!isAuthenticated">
        <router-link to="/signin">
          <div class="text-blue-500 hover:text-blue-800 cursor-pointer">Login</div>
        </router-link>
      </li>
      <li class="mr-6" v-if="isAuthenticated">
        <router-link to="/albums">
          <div class="text-blue-500 hover:text-blue-800 cursor-pointer">Albums</div>
        </router-link>
      </li>
      <li class="mr-6" v-if="isAuthenticated">
        <router-link to="/polly">
          <div class="text-blue-500 hover:text-blue-800 cursor-pointer">Polly</div>
        </router-link>
      </li>
      <li class="mr-6" v-if="isAuthenticated">
        <router-link to="/hello">
          <div class="text-blue-500 hover:text-blue-800 cursor-pointer">Hello</div>
        </router-link>
      </li>
      <li class="mr-6" v-if="isAuthenticated">
        <router-link to="/chats">
          <div class="text-blue-500 hover:text-blue-800 cursor-pointer">Chats</div>
        </router-link>
      </li>
      <li class="mr-6" v-if="isAuthenticated">
        <button class="text-blue-500 hover:text-blue-800 cursor-pointer" @click="logout">Logout</button>
<!--        <base-button @click="logout">Logout</base-button>-->
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  methods: {
    async logout() {
      await this.$store.dispatch("auth/logout");
      this.$router.push('/signin');
    },
    async authenticated() {
      return false;
    }
  },
  computed: {
    isAuthenticated() {
      // do not use async calls (lie this.$store.dispatch("auth/currentUser")) in computed - they return wrong value(s)
      return this.$store.getters['auth/isAuthenticated'];
    }
  }
}
</script>
