<template>
  <div>
    <base-dialog :show="!!error" title="An error occurred" @close="handleError">
      <p>{{ error }}</p>
    </base-dialog>
    <base-dialog fixed :show="isLoading" title="Loading...">
      <base-spinner></base-spinner>
    </base-dialog>
    <h3>Albums</h3>
    <div class="flex flex-col m-auto w-64">
      <input class="my-4 bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700"
             placeholder="Album name ..."
             type="text"
             v-model="albumName"/>
      <button class="btn-blue mb-4" @click="createAlbum">Create Album</button>
    </div>
    <div class="text-red-500">{{ error }}</div>
    <hr />
    <h5 class="mt-4 text-2xl">List of Albums</h5>
    <div class="flex flex-wrap w-full m-auto justify-center">
      <div v-for="album in albums"
           :key="album.albumId"
           class="cursor-pointer mt-4 ml-4 w-3/12 h-24 shadow-xl flex items-center justify-center"
           @click="openAlbumDetail(album)">
        <div class="text-2xl">{{ album.albumName }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseDialog from "@/components/BaseDialog.vue";
import BaseSpinner from "@/components/BaseSpinner.vue";
import axios from "axios";

export default {
  components: {
    BaseDialog,
    BaseSpinner,
  },
  data() {
    return {
      albums: [],
      albumName: '',
      error: '',
      isLoading: false
    }
  },
  methods: {
    openAlbumDetail(album) {
      // console.log('/album/' + album.albumId);
      this.$router.push('/album/' + album.albumId)
    },
    async createAlbum() {
      this.error = ''
      if (this.albumName === '') {
        this.error = 'Please enter an album name'
        return;
      }

      const newAlbum = {
        albumName: this.albumName,
      }

      const apiPath = '/albums'
      const headers = await this.$store.dispatch('auth/signPOST', {
        apiPath: apiPath,
        body: newAlbum
      });

      const url = process.env.VUE_APP_API_URL + apiPath
      await axios.post(url, newAlbum, {
        headers: headers
      }).then((response) => {
        const albumId = response.data.id;
        this.$router.replace("/album/" + albumId);
      }).catch((error) => {
        console.log(error)
        this.error = error.message;
        this.isLoading = false
      })
    },
    async fetch() {
      this.error = ""
      this.isLoading = true;

      const apiPath = '/albums'
      const headers = await this.$store.dispatch('auth/signGET', apiPath)

      const url = process.env.VUE_APP_API_URL + apiPath
      await axios.get(url, {
        headers: headers
      }).then((response) => {
        this.albums = response.data.albums;
        this.isLoading = false
      }).catch((error) => {
        console.log(error)
        this.error = error.message;
        this.isLoading = false
      });
    },
    handleError() {
      this.error = null
    }
  },
  async mounted() {
    await this.fetch()
  }
}
</script>
